*,html{margin:0px;padding:0px}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dzu-dropzone{
  min-height: 9.6em !important;
  max-height: 9.6em !important;
}
.dzu-inputLabel{
  color:rgba(0, 0, 0, 0.6) !important;
  font-size: 0.8em !important;
}